/**** NEWS ****/

export default function(){
}

/*** global functions ***/
//thumbsUp
window.ToggleThumbNews = function(element, news_id){
	$.ajax({
		headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') },
		url: $(element).attr("href"),
		method: "post",
		data: {"news_id":news_id},
	}).done(function(data){
		if(data.status == 'success'){
			$(element).find('.icon').toggleClass('material-icons');
			$(element).find('.icon').toggleClass('material-icons-outlined');
			if(data.newstatus == "mark"){
				$(element).find('.thumbs-nb').html(parseInt($(element).find('.thumbs-nb').html())+1);
			}
			else{
				$(element).find('.thumbs-nb').html(parseInt($(element).find('.thumbs-nb').html())-1);
			}
		}
	});
}
