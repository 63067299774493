export default function(){
	//show detail onclick
	$(document).on("click",".contact",function(e){
		e.preventDefault();
		var target = $(this).data("target");
		$.get(target,function(data){
			$("#address-detail").html(data.html);
		});
	});

	//search fields
	$(document).on("change keyup","[name=searchName], [name=searchBranch]",function(e){
		var qname =  $("[name=searchName]").val();
		var qbranch = $("[name=searchBranch]").find("option:selected").val();

		console.log(qname);
		console.log(qbranch);
		var q = $(e.currentTarget).val();
		$("#addressbook").find('.contact')
		.hide()
		.filter(':containsi("' + qname + '")')
		.filter(':containsi("' + qbranch + '")')
		.show();
		/*
		if(q.length > 0){
		}
		else{
			$("#addressbook").find('.contact').show();
		}
		*/
	});
}
