/**** SETTINGS ****/

export default function(){
	/*** global ***/
	//file upload
	$(document).on('click','#uploadFiles',function(){
		var target = $(".fileupload").data('target');
		var file_data = $(".fileupload").find('input').prop('files');
		var fdata = new FormData();
		$.each(file_data, function(i,v){
			fdata.append('files['+i+']', v);
		});
		$.ajax({
			type: "POST",
			url: target,
			data: fdata,
	    processData: false,
	    contentType: false,
			success: function(data){
				if(data.status == "success"){
					$(".filelist").html(data.html);
					$(".fileupload").find('input').replaceWith($(".fileupload").find('input').val('').clone(true));
				}
				else{
					alert("Během nahrávání nastala chyba");
				}
			}
		});
	});
    if($("#description_html").length > 0)
    {
        var html_desc = document.getElementById('description_html')
        html_desc.addEventListener('shown.bs.modal', function (event) {
            //get and place content into textarea
            var oldC = $("input#desc").val();
            $("#description_html_textarea").val(oldC);
        });
        html_desc.addEventListener('hide.bs.modal', function (event) {
            var newC = document.getElementById("description_html_textarea").value;
            //insert into trix
            var element = document.querySelector("trix-editor");
            element.editor.setSelectedRange([0, 9999])
            element.editor.insertHTML(newC);
        });
    }

    //HR save user BULK
    if($("#userSaveBulkModal").length){
        document.getElementById('userSaveBulkModal').addEventListener('shown.bs.modal', function (event) {
            //načíst title
            $("#userSaveBulkModal .modal-title span").html($(event.relatedTarget).text());
            //načíst a nastavit akci a hodnotu
            var action = $(event.relatedTarget).data('action').split('=');
            $("#userSaveBulkModal #action").attr('name',action[0]).val(action[1]);
            //načíst IDs
            var lines = $("#users table tbody").find('tr :checkbox:checked');
            var ids = "";
            $.each(lines, function(i,v){
                var id = $(v).closest("tr").data('id');
                ids = ids+id+", ";
            });
            //display IDs in form
            $(this).find('.ids').html(ids);

        });
        $(document).on('submit','#userSaveBulkModal form',function(e){
            e.preventDefault();
            var lines = $("#users").find('table tr :checkbox:checked');
            var action = $(this).find('#action');
            var name = action.attr('name');
            var value = action.attr('value');
            var form = $(this);
            $.each(lines, function(i,v){
                var obj = {id: $(v).closest("tr").data('id')};
                obj[name] = value;
                $.ajax({
                    url: form.attr('action'),
                    type: form.attr('method'),
                    data: obj,
                    success:function(data){

                    }
                });
            }).promise().done( function(){ window.location.reload() } );
        });
    }

    /* DRIVE TEMPLATE SWITCH */
    //isTemplate();
    $(document).on('change','input#sablona',function() {
        //isTemplate()
    });
    function isTemplate(){
        let elem = $('#driveRequestUserIdsContainer');
        let user = $('#driveRequestUserIds');
        let branch = $('#branchSelect');
        if(!$("input#sablona").is(":checked")){
            //zobrazit selectboxy
            elem.show();
            // set required by radio
            let value = $('input[name="userBranchType"]:checked').val();
            if (value == "user") {
                user.attr("required", "true");
                branch.removeAttr("required");
            } else {
                branch.attr("required", "true");
                user.removeAttr("required");
            }
        }
        else{
            elem.hide();
            user.removeAttr("required");
            branch.removeAttr("required");
        }
    }
}
