
window.Popper = require('popper.js').default;
window.$ = window.jQuery = require('jquery');
window.bootstrap = require('bootstrap');
require('bootstrap-select/dist/js/bootstrap-select');

$.extend($.expr[':'], {
  'containsi': function(elem, i, match, array) {
    return (elem.textContent || elem.innerText || '').toLowerCase()
    .indexOf((match[3] || "").toLowerCase()) >= 0;
  }
});

//enable tooltip
$('[data-toggle="tooltip"]').tooltip();
//require('fullcalendar');

import InitStream from './stream.js';

if($("#streams").length){
  InitStream();
}

import InitNews from './news.js';

if($("#news").length){
  InitNews();
}

import InitAddressbook from './addressbook.js';

if($("#addressbook").length){
  InitAddressbook();
}

import InitSettings from './settings.js';

if($("#settings").length){
  InitSettings();
}

import InitLunches from './lunches.js';

if($("#lunches").length){
  InitLunches();
}

/*
import InitAttendance from './attendance.js';

if($("#calendar").length){
    InitAttendance();
}
*/

//set header for all ajax posts
$.ajaxSetup({
  headers: {
    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
  }
});

//odeslání odkazu s parametry(GET) jako POST
$(document).on('click','.unipost',function(e){
  e.preventDefault();
  var elem = $(e.currentTarget);
  $.post( elem.attr('href'), function( data ) {
    if(elem.data('type') == 'toggle'){
      elem.find('span').toggleClass('collapse');
    }
    else{
      if(data.status == 'success'){
        elem.closest('li').slideUp().remove();
      }
    }
  });
});

//odeslání odkazu s parametry(GET) jako POST
$(document).on('click','.delete',function(e){
  e.preventDefault();
  if (confirm('Jste si jisti, že chcete vymazat tento záznam?')) {
    var elem = $(e.currentTarget);
    $.ajax({
      url: elem.attr('href'),
      type: 'DELETE',
      success: function(data){
        if(elem.closest("tr").length > 0) {
          elem.closest("tr").slideUp();
        } else if(elem.closest("li").length > 0) {
              elem.closest("li").slideUp();
        } else{
          elem.closest(".card").slideUp();
        }
      }
    });
  }
});

//duplicate previous element
/*
  .duplicate selects actual button which is calling action
  .duplicateprev selects closest container in relation to .duplicate wherefrom is getting previous element
*/
$(document).on('click','.duplicate',function(e){
  e.preventDefault();
  var originalelement =  $(this).closest('.duplicateprev').prev();
  let clone = originalelement.clone();
  clone.find("input:not(.ignore)").val("");
  originalelement.after(clone);
});

//table checkall function
$(document).on('click tap', '.checkall', function(event) {
    if(this.checked) {
        // Iterate each checkbox
        $(this).closest("table").find(':checkbox').each(function() {
            this.checked = true;
        });
    } else {
        $(this).closest("table").find(':checkbox').each(function() {
            this.checked = false;
        });
    }
});

//submit table filter
$(document).on('keyup change', 'table input, table select', function(e) {
    if (e.key === 'Enter' || e.keyCode === 13) {
        var base = $(this).closest("table").data("base-url");
        var inputs = $(this).closest('thead').find(':input');
        console.log(inputs)
        window.location.href = base+"?"+inputs.serialize();
    }
});

//table bulk selects
$(document).on('click','a[data-toggle=select]',function(e){
    e.preventDefault();
    var table = $(this).closest('.card-body').find('table');
    //all clear
    table.find('tr :checkbox').each(function(){
        this.checked = false;
    });
    //select
    table.find('tr[data-'+$(this).data('select')+'] :checkbox').each(function(){
        this.checked = true;
    });
});

//range filter switch
$(document).on('change','[name=range]',function(){
    if($("[name='range']:checked").val() === 'own'){
        $(".own-range-fields").removeClass('d-none')
            .find('input').attr('disabled',false).attr('required',true);
    }
    else{
        $(".own-range-fields").addClass('d-none')
            .find('input').attr('disabled',true).attr('required',false);
    }

});

//trix global workarounds
//click on attachments opens in new window
$(document).on('click touchstart','figure.attachment',function(e){
    e.preventDefault();
    window.open($(this).find("a").attr('href'));
});


//toggle SMS notifier
$(document).on('change','#can_notify',function(){
    //toggle branches
    $("#notify_branches").toggle(function(){
        //if hidden, turnoff branches
        if($("#notify_branches").is(":hidden")){
            $("#notify_branches").find("input[type=checkbox]").prop('checked',false);
        }
    });
});
