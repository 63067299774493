/**** LUNCHES ****/

export default function(){
	$(document).on('click','.selectfood',function(e){
		e.preventDefault();
		var target = $(this).data('target');
		var lunch_id = $(this).data('id');
		var checker = $(this);
		$.ajax({
			type: "POST",
			url: target,
			data: {'lunch_id': lunch_id},
			success: (function(data){
				if('success' === data.status){
                    //toggle button
					checker.find('.status').toggleClass('collapse');
					//reload panel
					loadLunches();
				}
			})
		});
	});

	//load My-Lunches box
	function loadLunches(){
		var container = $("#my-lunches");
		var target = container.data('target');
		$.ajax({
			type: 'GET',
			url: target,
			success: (function(data){
				if(data.status === 'success'){
					//toggle button
					container.html(data.html);
				}
			})
		});
	}
}
