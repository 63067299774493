/**** STREAM ****/
//triggers
export default function(){
	$(document).on("click",".toggleReply", function(e){
		e.preventDefault();
		var elem = $(e.currentTarget);
		$("[data-reply_to="+elem.data("id")+"]").slideToggle();
	});

	//button submit
	$(document).on("submit",".createstream", function(e){
		SubmitStream(e);
	});

	//submit stream on ctrl+enter
	$(document).on('keypress','.createstream',function(e) {
		if (event.ctrlKey && event.keyCode === 13){
			SubmitStream(e);
		}
	});

	//infinity loader
	let page = $(".streamline[data-depth=1]").data("page");
	$(window).on('scroll',function(){
		if(page != "eol" && $(window).scrollTop() + $(window).height() == $(document).height()) {
			getStreams(page,function(data){
				if(data.success == 'success'){
					page = page+1
				}
				else{
					$(".streamline[data-depth=1]").attr("data-page","eol");
					$(".streamline[data-depth=1]").next('.eol-info').removeClass('d-none').addClass('d-flex');
					page = "eol";
				}
				$(".streamline[data-depth=1]").append(data.html);
			});
		}
	});

}

/*** private functions ***/

//submit new stream/message or reply
function SubmitStream(e){
	e.preventDefault();

	var form = $(e.currentTarget);
	var reply_to = form.find('[name=reply_to]').val();
	var message = form.find('[name=message]').val();
	var attachments = form.find('#attachment-stream-streamArea-new-model').val();
	var can_notify = form.find('[name=can_notify]:checked').val();
	var notify = form.find('[name=notify] option:selected').val();

	$.ajax({
		headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') },
		url: form.attr("action"),
		method: "post",
		data: {
			"message":message,
			"reply_to":reply_to,
			"attachments":attachments,
			"can_notify":can_notify,
			"notify":notify,
		},
	}).done(function(data){
		if(data.status == 'success'){
			form.trigger('reset');
            $("#notify_branches").hide().find("input[type=checkbox]").prop('checked',false);
            if(reply_to !== undefined){
				form.hide();
			}
			form.closest(".streams").find('.streamline').first().prepend(data.html);
		}
	});
};

//get 10 streams
function getStreams(page,callback){
	var streamline = $(".streamline[data-depth=1]");
	var target = streamline.data("target");
	page = page+1;
	$.ajax({
		headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') },
		url: target+"?page="+page,
		method: "get",
	}).done(function(data){
		callback(data);
	});
}


/*** global functions ***/

//thumbsUp
window.ToggleThumb = function(element, stream_id){
	$.ajax({
		headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') },
		url: $(element).attr("href"),
		method: "post",
		data: {"stream_id":stream_id},
	}).done(function(data){
		if(data.status == 'success'){
			$(element).find('.icon').toggleClass('material-icons');
			$(element).find('.icon').toggleClass('material-icons-outlined');
			if(data.newstatus == "mark"){
				$(element).find('.thumbs-nb').html(parseInt($(element).find('.thumbs-nb').html())+1);
			}
			else{
				$(element).find('.thumbs-nb').html(parseInt($(element).find('.thumbs-nb').html())-1);
			}
		}
	});
}
